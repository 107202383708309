import React, { useMemo, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import initialData from '../data.json';
import './CoffeeTable.css'; // Import the CSS styles
import axios from 'axios'; // Import Axios
import Spinner from './Spinner'; // Import the Spinner component

// WooCommerce API settings
const apiUrl = 'https://backwoodsgrind.com/wp-json/wc/v3/orders'; // Update with your store URL
const consumerKey = 'ck_0d9a18f39d5d2bf67ebb7e4e8fea45956ae71577'; // Replace with your Consumer Key
const consumerSecret = 'cs_adf69d742cba4579894470d17ccb401ad57e6790'; // Replace with your Consumer Secret

// Constants
const bagsPerBatch = 7; // 7 bags per batch
const ouncesPer5lbBag = 80; // 5 pounds in ounces
const timePerBatch = 20; // Time in minutes per batch

// Mapping for signature roasts
const signatureRoastMapping = {
  "Wren & Ivy": ["Sunset", "Camp House"],
  "Lost Brake": ["High Noon", "Fireside"],
  "Pinhoti Roast": ["Camp House", "Fireside"]
};

// Constants for mapping SKU to coffee names and bag sizes
const mapProductIdToField = {
  // Main Products
  "6340": { coffee: "Sunrise", quantity: 0, fiveLbBags: 0 },
  "6341": { coffee: "Sunrise", quantity: 0, fiveLbBags: 0 },
  "6342": { coffee: "Sunrise", quantity: 0, fiveLbBags: 0 },
  "6343": { coffee: "Sunrise", quantity: 0, fiveLbBags: 0 },
  "6344": { coffee: "Sunrise", quantity: 0, fiveLbBags: 0 },
  "6345": { coffee: "Sunrise", quantity: 0, fiveLbBags: 0 },
  "24764": { coffee: "Sunrise", quantity: 0, fiveLbBags: 0 },
  "33791": { coffee: "Sunrise", quantity: 0, fiveLbBags: 0 },
  "24766": { coffee: "Sunrise", quantity: 0, fiveLbBags: 0 },
  "33792": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "6347": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "24751": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "33043": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "6348": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "6349": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "6350": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "6351": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "6352": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "33044": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "33045": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "33046": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "33047": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "33048": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "33049": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "33050": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "33051": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "33052": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "33053": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "24753": { coffee: "High Noon", quantity: 0, fiveLbBags: 0 },
  "24777": { coffee: "Sunset", quantity: 0, fiveLbBags: 0 },
  "33790": { coffee: "Sunset", quantity: 0, fiveLbBags: 0 },
  "81": { coffee: "Sunset", quantity: 0, fiveLbBags: 0 },
  "82": { coffee: "Sunset", quantity: 0, fiveLbBags: 0 },
  "83": { coffee: "Sunset", quantity: 0, fiveLbBags: 0 },
  "84": { coffee: "Sunset", quantity: 0, fiveLbBags: 0 },
  "6236": { coffee: "Sunset", quantity: 0, fiveLbBags: 0 },
  "6322": { coffee: "Sunset", quantity: 0, fiveLbBags: 0 },
  "10192": { coffee: "Camp House", quantity: 0, fiveLbBags: 0 },
  "10293": { coffee: "Camp House", quantity: 0, fiveLbBags: 0, isFiveLbBag: true }, // Add flag for 5lb bags
  "24652": { coffee: "Camp House", quantity: 0, fiveLbBags: 0 },
  "33796": { coffee: "Camp House", quantity: 0, fiveLbBags: 0 },
  "10194": { coffee: "Camp House", quantity: 0, fiveLbBags: 0 },
  "10196": { coffee: "Camp House", quantity: 0, fiveLbBags: 0 },
  "10198": { coffee: "Camp House", quantity: 0, fiveLbBags: 0 },
  "10200": { coffee: "Camp House", quantity: 0, fiveLbBags: 0 },
  "10202": { coffee: "Camp House", quantity: 0, fiveLbBags: 0 },
  "10320": { coffee: "Camp House", quantity: 0, fiveLbBags: 0, isFiveLbBag: true }, // Add flag for 5lb bags
  "10174": { coffee: "Fireside", quantity: 0, fiveLbBags: 0 },
  "10176": { coffee: "Fireside", quantity: 0, fiveLbBags: 0 },
  "10178": { coffee: "Fireside", quantity: 0, fiveLbBags: 0 },
  "10180": { coffee: "Fireside", quantity: 0, fiveLbBags: 0 },
  "10182": { coffee: "Fireside", quantity: 0, fiveLbBags: 0 },
  "10172": { coffee: "Fireside", quantity: 0, fiveLbBags: 0 },
  "24679": { coffee: "Fireside", quantity: 0, fiveLbBags: 0 },
  "33795": { coffee: "Fireside", quantity: 0, fiveLbBags: 0 },
  "24681": { coffee: "Fireside", quantity: 0, fiveLbBags: 0 },
  "43589": { coffee: "Fireside", quantity: 0, fiveLbBags: 0, isFiveLbBag: true }, // Add flag for 5lb bags
  "10146": { coffee: "Nightcap", quantity: 0, fiveLbBags: 0 },
  "10362": { coffee: "Nightcap", quantity: 0, fiveLbBags: 0, isFiveLbBag: true }, // Add flag for 5lb bags
  "24738": { coffee: "Nightcap", quantity: 0, fiveLbBags: 0 },
  "10148": { coffee: "Nightcap", quantity: 0, fiveLbBags: 0 },
  "10150": { coffee: "Nightcap", quantity: 0, fiveLbBags: 0 },
  "10152": { coffee: "Nightcap", quantity: 0, fiveLbBags: 0 },
  "10154": { coffee: "Nightcap", quantity: 0, fiveLbBags: 0 },
  "10156": { coffee: "Nightcap", quantity: 0, fiveLbBags: 0 },
  "10372": { coffee: "Nightcap", quantity: 0, fiveLbBags: 0, isFiveLbBag: true }, // Add flag for 5lb bags
  "10382": { coffee: "Nightcap", quantity: 0, fiveLbBags: 0, isFiveLbBag: true }, // Add flag for 5lb bags
  "24740": { coffee: "Nightcap", quantity: 0, fiveLbBags: 0 },
  "34077": { coffee: "Pinhoti Roast", quantity: 0, fiveLbBags: 0 },
  "42475": { coffee: "Pinhoti Roast", quantity: 0, fiveLbBags: 0 },
  "42493": { coffee: "Pinhoti Roast", quantity: 0, fiveLbBags: 0 },
  "42494": { coffee: "Pinhoti Roast", quantity: 0, fiveLbBags: 0 },
  "34473": { coffee: "Pinhoti Roast", quantity: 0, fiveLbBags: 0 },
  "34474": { coffee: "Pinhoti Roast", quantity: 0, fiveLbBags: 0 },
  "34476": { coffee: "Pinhoti Roast", quantity: 0, fiveLbBags: 0 },
  "45295": { coffee: "Pinhoti Roast", quantity: 0, fiveLbBags: 0 },
  "45296": { coffee: "Pinhoti Roast", quantity: 0, fiveLbBags: 0 },
  "45293": { coffee: "Pinhoti Roast", quantity: 0, fiveLbBags: 0 },
  "45294": { coffee: "Pinhoti Roast", quantity: 0, fiveLbBags: 0 },
  "42487": { coffee: "Lost Brake", quantity: 0, fiveLbBags: 0 },
  "42497": { coffee: "Lost Brake", quantity: 0, fiveLbBags: 0 },
  "42498": { coffee: "Lost Brake", quantity: 0, fiveLbBags: 0 },
  "40303": { coffee: "Lost Brake", quantity: 0, fiveLbBags: 0 },
  "40304": { coffee: "Lost Brake", quantity: 0, fiveLbBags: 0 },
  "40305": { coffee: "Lost Brake", quantity: 0, fiveLbBags: 0 },
  "40306": { coffee: "Lost Brake", quantity: 0, fiveLbBags: 0 },
  "40307": { coffee: "Lost Brake", quantity: 0, fiveLbBags: 0 },
  "40308": { coffee: "Lost Brake", quantity: 0, fiveLbBags: 0 },
  "42481": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "42495": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "42496": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "36287": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "36288": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "36289": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "36289": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "36289": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "36290": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "36291": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "36292": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "37067": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "37068": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "37069": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "37070": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "37071": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "37072": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "40230": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "40231": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "40233": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "40234": { coffee: "Wren & Ivy", quantity: 0, fiveLbBags: 0 },
  "6479": { coffee: "3 Packs", quantity: 0, fiveLbBags: 0 },
  "6480": { coffee: "3 Packs", quantity: 0, fiveLbBags: 0 },
  "6484": { coffee: "3 Packs", quantity: 0, fiveLbBags: 0 },
  "11754": { coffee: "6 Packs", quantity: 0, fiveLbBags: 0 },
  "43532": { coffee: "6 Packs", quantity: 0, fiveLbBags: 0 }
};

const CoffeeTable = () => {
    const [data, setData] = useState(
      initialData.map(item => ({
        ...item,
        twelveOunceBags: 0,
        fiveLbBags: 0,
        batches: 0,
        lbs: 0,
        batchDone: []
      }))
    );
  
    const [loading, setLoading] = useState(false); // Loading state
  
    useEffect(() => {
        const fetchProcessingOrders = async () => {
            setLoading(true);
            let allOrders = [];
            let currentPage = 1;
            let morePagesAvailable = true;
  
            try {
                while (morePagesAvailable) {
                    const response = await axios.get(apiUrl, {
                        auth: {
                            username: consumerKey,
                            password: consumerSecret,
                        },
                        params: {
                            status: 'processing',
                            after: '2024-01-01T00:00:00',
                            per_page: 100,
                            page: currentPage,
                        },
                    });
  
                    allOrders = allOrders.concat(response.data);
                    morePagesAvailable = response.data.length === 100;
                    currentPage += 1;
                }
  
                processOrderData(allOrders);
            } catch (error) {
                console.error("Error fetching processing orders:", error);
            } finally {
                setLoading(false);
            }
        };
  
        fetchProcessingOrders();
    }, []);  
  
    const processOrderData = (orders) => {
      const productIdGroupedQuantities = {};
  
      // Step 1: Aggregate quantities for each product ID
      orders.forEach(order => {
          order.line_items.forEach(lineItem => {
              let productId = lineItem.product_id.toString();
  
              // Initialize if productId is not in the grouped quantities object
              if (!productIdGroupedQuantities[productId]) {
                  productIdGroupedQuantities[productId] = { quantity: 0, fiveLbBags: 0 };
              }
  
              // Corrected logic to use isFiveLbBag flag from the mapProductIdToField mapping
              if (mapProductIdToField[productId] && mapProductIdToField[productId].isFiveLbBag) {
                  productIdGroupedQuantities[productId].fiveLbBags += lineItem.quantity;
              } else {
                  productIdGroupedQuantities[productId].quantity += lineItem.quantity;
              }
          });
      });
  
      // Step 2: Update the data state based on grouped Product ID quantities
      const updatedData = data.map(item => {
          // Find all product IDs that match the current coffee type
          const matchingProductIds = Object.keys(mapProductIdToField).filter(
              productId => mapProductIdToField[productId].coffee === item.coffee
          );
  
          // Aggregate all quantities for the coffee type
          let totalQuantity = 0;
          let totalFiveLbBags = 0;
  
          matchingProductIds.forEach(productId => {
              if (productIdGroupedQuantities[productId]) {
                  totalQuantity += productIdGroupedQuantities[productId].quantity;
                  totalFiveLbBags += productIdGroupedQuantities[productId].fiveLbBags;
              }
          });
  
          return {
              ...item,
              twelveOunceBags: totalQuantity,
              fiveLbBags: totalFiveLbBags
          };
      });
  
      // Step 3: Set updated data state
      setData(updatedData);
  };  

    const recalculateValues = () => {
        const updatedData = data.map(item => {
            if (!Object.keys(signatureRoastMapping).includes(item.coffee) && item.coffee !== "6 Packs" && item.coffee !== "3 Packs") {
                let totalBags = item.twelveOunceBags;
                let additionalBatchesFrom5lbBags = item.fiveLbBags;

                const sixPack = data.find(i => i.coffee === "6 Packs");
                if (sixPack) {
                    totalBags += (sixPack.twelveOunceBags * 0.16667);
                }

                Object.entries(signatureRoastMapping).forEach(([signature, originals]) => {
                    if (originals.includes(item.coffee)) {
                        const signatureRoast = data.find(i => i.coffee === signature);
                        if (signatureRoast) {
                            totalBags += (signatureRoast.twelveOunceBags / 2);
                        }
                    }
                });

                item.lbs = (totalBags * 12 + additionalBatchesFrom5lbBags * ouncesPer5lbBag) / 16;
                item.batches = Math.ceil(totalBags / bagsPerBatch) + additionalBatchesFrom5lbBags;
                item.batchDone = Array(item.batches).fill(false);
            } else if (item.coffee === "6 Packs") {
                let totalBags = item.twelveOunceBags;
                item.batches = Math.ceil(totalBags / bagsPerBatch);
                item.batchDone = Array(item.batches).fill(false);
            }

            return item;
        });

        setData(updatedData);
    };

    const handleInputBlur = (rowIndex, field, value) => {
        const newData = [...data];
        newData[rowIndex][field] = parseFloat(value) || 0;
        recalculateValues();
    };

    const handleCheckboxChange = (rowIndex, batchIndex) => {
        const newData = [...data];
        newData[rowIndex].batchDone[batchIndex] = !newData[rowIndex].batchDone[batchIndex];
        setData(newData);
    };

    const columns = useMemo(() => [
        { Header: 'Coffee', accessor: 'coffee' },
        {
            Header: 'Quantity',
            accessor: 'twelveOunceBags',
            Cell: ({ row }) => (
                <input
                    type="number"
                    style={{ width: '60px' }}
                    defaultValue={row.values.twelveOunceBags}
                    onBlur={(e) => handleInputBlur(row.index, 'twelveOunceBags', e.target.value)}
                />
            )
        },
        {
            Header: '5lb Bags',
            accessor: 'fiveLbBags',
            Cell: ({ row }) =>
                (row.original.coffee !== "6 Packs" && row.original.coffee !== "3 Packs") ? (
                    <input
                        type="number"
                        style={{ width: '60px' }}
                        defaultValue={row.values.fiveLbBags}
                        onBlur={(e) => handleInputBlur(row.index, 'fiveLbBags', e.target.value)}
                    />
                ) : null
        },
        {
            Header: '# of Batches',
            accessor: 'batches',
            Cell: ({ row, value }) =>
                (row.original.coffee !== "6 Packs" && row.original.coffee !== "3 Packs") ? value : null
        },
        {
            Header: 'Lbs',
            accessor: 'lbs',
            Cell: ({ row, value }) =>
                (row.original.coffee !== "6 Packs" && row.original.coffee !== "3 Packs") ? value.toFixed(2) : null
        },
        {
            Header: 'Done',
            accessor: 'batchDone',
            Cell: ({ row }) =>
                (row.original.coffee !== "6 Packs" && row.original.coffee !== "3 Packs") ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {row.original.batchDone.map((done, batchIndex) => (
                            <label key={batchIndex} style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="checkbox"
                                    checked={done}
                                    onChange={() => handleCheckboxChange(row.index, batchIndex)}
                                />
                                Batch {batchIndex + 1}
                            </label>
                        ))}
                    </div>
                ) : null
        }
    ], [data]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

    const totalBatches = data.reduce((sum, item) => {
        if (item.coffee !== "6 Packs" && item.coffee !== "3 Packs") {
            return sum + item.batches;
        }
        return sum;
    }, 0);

    const totalTime = totalBatches * timePerBatch;
    const hours = Math.floor(totalTime / 60);
    const minutes = totalTime % 60;

    return (
        <div className="table-container">
            {loading && <Spinner />}
            <button 
                style={{ 
                    float: 'right', 
                    marginBottom: '10px', 
                    padding: '10px 15px', 
                    backgroundColor: '#28a745',
                    color: 'white', 
                    border: 'none', 
                    borderRadius: '5px', 
                    cursor: 'pointer' 
                }} 
                onClick={recalculateValues}
            >
                Update Batch Count
            </button>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} style={{ border: '1px solid #dee2e6' }}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        const { key, ...rest } = row.getRowProps();
                        return (
                            <tr key={key} {...rest}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} style={{ border: '1px solid #dee2e6', padding: '10px' }}>
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                    <tr>
                        <td colSpan={1} style={{ textAlign: 'right', fontWeight: 'bold' }}>Total Batches:</td>
                        <td style={{ textAlign: 'left' }}>{totalBatches}</td>
                    </tr>
                    <tr>
                        <td colSpan={1} style={{ textAlign: 'right', fontWeight: 'bold' }}>Estimated Time:</td>
                        <td style={{ textAlign: 'left' }}>{hours} hours {minutes} minutes</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default CoffeeTable;